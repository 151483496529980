import React from 'react';
import {render} from 'react-dom';

const COLORS = [
  {name: 'red', rgb: [255, 0, 0]},
  {name: 'blue', rgb: [0, 0, 255]},
  {name: 'yellow', rgb: [255, 255, 0]},
  {name: 'white', rgb: [255, 255, 255]},
  {name: 'black', rgb: [0, 0, 0]},
  {name: 'most beautiful color', rgb: [54, 106, 255]}
];

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rgb: [
        Math.random(),
        Math.random(),
        Math.random()
      ].map((r) => Math.round(r * 255)),
      history: []
    };
  }

  blend(color) {
    this.setState(({rgb, history}) => {
      return {
        history: [rgb, ...history],
        rgb: rgb.map((component, i) => {
          return Math.round(component * 0.8 + color[i] * 0.2);
        })
      };
    });
  }

  undo() {
    this.setState((prevState) => {
      const [rgb, ...history] = prevState.history;
      return {history, rgb};
    });
  }

  render() {
    const rgb = `rgb(${this.state.rgb.join(', ')})`;

    return (
      <React.Fragment>
        <button
          disabled={!this.state.history.length}
          type="button"
          onClick={this.undo.bind(this)}
        >Undo</button>
        <div className="canvas" style={{background: rgb}}>
          <p className="rgb">{rgb}</p>
        </div>
        <div className="buttons">
          {
            COLORS.map(({name, rgb}, i) => {
              return (
                <button
                  key={i}
                  style={{background: `rgb(${rgb.join(', ')})`}}
                  type="button"
                  onClick={this.blend.bind(this, rgb)}
                >{name}</button>
              );
            })
          }
        </div>
      </React.Fragment>
    );
  }
}

render(<App />, document.getElementById('app'));
